.faq-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 20px;
}

.faq-list > li {
  border: 1px solid #ddd;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-list > li > h3 {
  font-weight: 400;
}

.faq-list > li > p {
  font-weight: 200;
  line-height: 25px;
}