header {
  position: fixed;
  width: 100%;
  z-index: 100;
  transition: all 0.3s ease 0s;
}

.header-background {
  background-color: rgba(0, 0, 0, 0.8);
}
.header-wrapper {
  display: flex;
  padding: 20px 0;
  padding-bottom: 30px;
  justify-content: space-between;
  align-items: center;
}

.header-wrapper > img {
  width: 230px;
}

.header-nav {
  display: flex;
  gap: 10px;
  align-items: flex-end;
  flex-direction: column;
}

.header-nav > ul {
  display: flex;
  gap: 25px;
  list-style-type: none;
}

.header-nav > ul > li {
  position: relative;
}

.header-nav > ul > li > a {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 200;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 1px;
  padding-bottom: 5px;
}

.header-nav > ul > li > a::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: rgba(255 255 0.5);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.header-nav > ul > li > a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.drop-down-container {
  padding-bottom: 10px;
}

.drop-down-container:hover .drop-box {
  top: 30px;
  opacity: 1;
  visibility: visible;
  transition: all 0.3s ease;
}

.drop-down-container:hover .rotate {
  color: rgba(255 255 0.5);
  transition: all 0.3s ease;
}

.drop-box {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  padding: 10px 0;
  transition: all 0.3s ease;
  width: 280px;
  top: 85px;
  list-style-type: none;
  background-color: rgba(0, 0, 0, 0.8);
}

.drop-box-responsive {
  padding: 0px 0;
  list-style-type: none;
}

.drop-box > li{
  width: 100%;
  padding: 10px 20px;
}

.drop-box-responsive > li {
  width: 100%;
  padding: 15px 20px;
  border-bottom: 1px solid #D2D2D2;
}

.drop-box-responsive > li:last-child {
  border: none;
  padding-bottom: 0;
}

.drop-box-responsive > li > a {
  text-decoration: none;
  color: #000;
  font-size: .9rem;
}

.drop-box > li > a {
  width: 100% !important;
  min-width: 100%;
  color: #cacaca;
  text-decoration: none;
  font-size: 0.8rem;
  font-weight: 200;
  transition: 0.3s;
}

.drop-box > li > a:hover {
  color: #fff;
}

.header-menu-text {
  display: flex;
  gap: 5px;
  align-items: center;
}

.header-menu-text > h3 {
  font-size: 1rem;
  font-weight: 600;
  text-shadow: 0px 0px 3px black;
  color: rgb(173, 216, 230);
}

.header-menu-text > i {
  font-size: 2rem;
  text-shadow: 0px 0px 3px black;
  color: rgb(173, 216, 230);
}

.active-menu-itm {
  border-bottom: 1px solid white;
}

.active-menu-itm:hover {
  border-bottom: none;
}

.reponsive-button-nav {
  font-size: 2rem;
  border: none;
  background-color: transparent;
  color: #fff;
  display: none;
}

@media only screen and (max-width: 1190px)  { 
  .header-nav > ul {
    display: none;
  }

  .hide-phone {
    display: none;
  }

  .header-nav {
    flex-direction: column-reverse;
  }

  .project-content-page {
    max-width: 80% !important;
  }

  .reponsive-button-nav { 
    display: flex;
  }
}


@media only screen and (max-width: 400px)  { 
  .header-wrapper > a > img {
    width: 200px !important;
  }
}


.responsive-nav-bar-container {
  position: fixed;
  top: 0;
  z-index: 2000;
  width: 100%;
  opacity: 0;
  display: flex;
  visibility: hidden;
  gap: 10px;
  flex-direction: column;
  align-items: flex-end;
  padding: 40px 40px;
  height: 100vh;
  overflow-y: scroll;
  background-color: #fff;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  transition: all 0.3s ease;
  transform: translateX(-200px);
}

.open-res-nav {
  visibility: visible;
  transform: translateX(0);
  opacity: 1;
  transition: all 0.3s ease;
}

/* @keyframes shownav {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: translateX(-200px);
  }
  100% {
    visibility: visible;
    
  }
} */




.responsive-nav-bar-container > ul {
  display: flex;
  flex-direction: column;
  width: 100%;
  list-style-type: none;
}

.responsive-nav-bar-container > ul > li {
  width: 100%;
  padding: 15px;
  padding-left: 0;
  border-bottom: 1px solid #D2D2D2;
}

.responsive-nav-bar-container > ul > li:last-child {
  border: none;
}

.responsive-nav-bar-container > ul > li > a {
  max-width: 100%;
  width: 100% !important;
  color: var(--dark1);
  text-decoration: none;
  padding: 15px;
  padding-left: 0;
  font-size: .9rem;
  font-weight: 500;
  transition: .3s;
}

.responsive-nav-bar-container > ul > li > a:hover {
  color: var(--primary_color);
}

.responsive-nav-bar-container > button {
  border: none;
  padding: 10px 20px;
}