.testimonal-page-list {
  display: flex;
  flex-direction: column;
  margin-top: -20px;
}

.testimonal-page-list > li {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  gap: 50px;
  border-bottom: 2px solid #ddd;
}

.testimonal-page-list > li > img { 
  width: 250px;
}

.testimonal-page-list > li > div {
  flex-direction: column;
  max-width: 700px;
  gap: 20px;
  display: flex;
}

.testimonal-page-list > li > div > p {
  font-weight: 300;
  line-height: 25px;
  font-size: .8rem;
}

.testimonal-page-list > li > div > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.testimonal-page-list > li > div > div > p {
  font-size: .8rem;
  font-weight: 300;
  font-style: italic;
}

@media only screen and (max-width: 750px)  {  
  .testimonal-page-list > li {
    flex-direction: column;
  }
}