.back-btn {
  position: fixed;
  background-color: rgba(0, 0, 0, 1);
  font-size: 1.4rem;
  color: rgba(255 255 0.5);
  border: none;
  bottom: -100px;
  border-radius: 4px;
  right: 30px;
  width: 60px;
  height: 60px;
  transition: .3s;
  cursor: pointer;
}

.show-back-to-top {
  bottom: 30px;
}

.back-btn:hover {
  scale: .9;
}