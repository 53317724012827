.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  width: 100%;
  object-fit: cover;
}

/* .mySwiper {
  position: relative;
  width: 100%;
  background-size: cover !important;
  height: 100%;
}

.swiper-slide {
  width: 100%;
}

.swiper-slide > .slide-img {
  width: 100%;
  object-fit: cover;
} */

.swiper {
  margin-left: auto;
  margin-right: auto;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: #fff;
  border: #fff;
  border-radius: 100%;
  padding: 25px;
  color: black;
}

.swiper-button-next:after,
.swiper-button-prev:after {
  font-size: 30px;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
  opacity: 0;
}

.swiper-pagination-bullet-active {
  background-color: red !important;
}

.swiper-pagination-bullet {
  background-color: rgba(255, 0, 0, 0.5);
}
