.error-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.button {
  display: flex;
  justify-content: center;
}

.button > button {
  cursor: pointer;
  padding: 10px;
  color: white;
  font-size: 30px;
  background-color: black;
  border-radius: 50%;
  border: 0px;
}
