footer {
  background-color: rgba(0, 0, 0, .9);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0;
}

.footer-content {
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.footer-content > a {
  text-decoration: none;
  color: #fff;
  font-weight: 300;
  position: relative;
}

.footer-content > a::after {
  content: '';
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1px;
  bottom: -6px;
  left: 0;
  background-color: rgba(255 255 0.5);
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.footer-content > a:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media only screen and (max-width: 500px)  { 
  .footer-content {
    flex-direction: column;
    gap: 20px;
  }
}