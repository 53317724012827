* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  position: relative;
}

.container-style {
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-sizing {
  max-width: 1340px;
  width: 90%;
  margin: 0 15px;
}

.heavy-text {
  font-weight: 600 !important;
}

.single-text-box {
  gap: 40px;
  padding: 80px 0;
  flex-direction: column;
}

.single-content-box {
  gap: 30px;
  max-width: 1000px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.single-content-box > p {
  font-weight: 300;
  line-height: 25px;
}

.single-content-box > h2 {
  font-weight: 400;
  text-align: center;
  margin-bottom: 20px;
}

.simple-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.simple-list > li {
  font-weight: 300;
  line-height: 25px;
}