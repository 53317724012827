.page-header-container {
  display: flex;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-header-container > div {
  padding-top: 230px;
  padding-bottom: 130px;
  align-items: center;
  display: flex;
  color: #fff;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.page-header-container > div h1 {
  font-weight: 100;
  text-align: center;
  font-size: 3.5rem;
  text-shadow: 0px 0px 5px black !important;
}

.page-header-container > div h2 {
  font-weight: 200;
  text-align: center;
  font-size: 1.2rem;
  max-width: 800px;
  text-shadow: 0px 0px 5px black !important;
}
