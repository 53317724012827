.main-banner-section {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.page-spacing {
  padding: 100px 0;
  background-image: url('../../assets/background-project.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.mySwiper {
  position: relative;
  width: 100%;
  background-size: cover !important;
  height: 100%;
}

.swiper-slide {
  width: 100%;
}

.swiper-slide > .slide-img {
  width: 100%;
  object-fit: cover;
}

.banner-home {
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.7);
  position: absolute;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
  display: flex;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  padding-top: 100px;
}

.banner-home p {
  color: #fff;
  text-shadow: 0px 0px 3px black;
  line-height: 40px;
  font-weight: 300;
  font-size: 1.4rem;
  max-width: 70%;
}

.banner-home h2 {
  color: #fff;
  font-weight: 400;
  text-shadow: 0px 0px 3px black;
}

.banner-home button {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  word-spacing: 0px;
  display: flex;
  cursor: pointer;
  align-items: center;
  gap: 10px;
  transition: 0.3s;
  font-size: 0.9rem;
  font-weight: 300 !important;
}

.banner-home button:hover {
  background-color: #ddd;
}

.button-banner {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.button-banner > button > i {
  font-size: 1.5rem !important;
}

.project-content-page {
  text-align: center;
  gap: 25px;
  line-height: 25px;
  max-width: 70%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sec-subtext {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 300;
  padding: 20px 0;
}

.project-content-page > p {
  font-weight: 300;
}

.sec-list {
  display: grid;
  justify-content: center;
  gap: 40px;
  list-style-type: none;
  margin-top: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

.sec-list > li {
  max-width: 500px;
}

.list-left-project {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.list-left-project > li {
  font-weight: 300;
}

.ftr > a {
  padding: 30px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  text-align: left;
  color: #fff;
  text-decoration: none;
}

.ftr > a > h2 {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 30px;
}

.ftr > a > span {
  font-size: 0.8rem;
  font-weight: 200;
}

.hotel-list {
  background-image: url('../../assets/feature/hotel.jpg');
}

.living-list {
  background-image: url('../../assets/feature/condo.jpg');
}

.retail-hub {
  background-image: url('../../assets/feature/shop.jpg');
}

.amusement-hub {
  background-image: url('../../assets/feature/park.jpg');
}

.water-hub {
  background-image: url('../../assets/feature/waterparksmall.jpg');
}

.winter-hub {
  background-image: url('../../assets/feature/winter.jpg');
}

.theater-hub {
  background-image: url('../../assets/feature/theater.jpg');
}

.drive-hub {
  background-image: url('../../assets/feature/driveinsmall.jpg');
}

.gold-hub {
  background-image: url('../../assets/feature/golf.jpg');
}

.green-hub {
  background-image: url('../../assets/feature/green.jpg');
}

.sport-hub {
  background-image: url('../../assets/feature/sportsplexsmall.jpg');
}

.Premium-hub {
  background-image: url('../../assets/feature/adultzonesmall.jpg');
}

.slide-img {
  width: 100%;
  height: 100vh;
  max-width: 100%;
}

.modal-video {
  position: absolute;
  top: 50%;
  left: 50%;
  /* transform: translate(-50%, -50%); */
}

@keyframes modal-video {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes modal-video-inner {
  from {
    transform: translate(0, 100px);
  }
  to {
    transform: translate(0, 0);
  }
}
.modal-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000000;
  cursor: pointer;
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
}

.modal-video-effect-exit {
  opacity: 0;
}
.modal-video-effect-exit .modal-video-movie-wrap {
  -webkit-transform: translate(0, 100px);
  -moz-transform: translate(0, 100px);
  -ms-transform: translate(0, 100px);
  -o-transform: translate(0, 100px);
  transform: translate(0, 100px);
}

.modal-video-body {
  max-width: 960px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

.modal-video-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media (orientation: landscape) {
  .modal-video-inner {
    padding: 10px 60px;
    box-sizing: border-box;
  }
}

.modal-video-movie-wrap {
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%;
  background-color: #333;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video-inner;
  -webkit-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -ms-transition: -ms-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}
.modal-video-movie-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.modal-video-close-btn {
  position: absolute;
  z-index: 2;
  top: -45px;
  right: 0px;
  display: inline-block;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: none;
  background: transparent;
}
@media (orientation: landscape) {
  .modal-video-close-btn {
    top: 0;
    right: -45px;
  }
}
.modal-video-close-btn:before {
  transform: rotate(45deg);
}
.modal-video-close-btn:after {
  transform: rotate(-45deg);
}
.modal-video-close-btn:before,
.modal-video-close-btn:after {
  content: '';
  position: absolute;
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  margin-top: -1px;
  background: #fff;
  border-radius: 5px;
  margin-top: -6px;
}

@media only screen and (max-width: 950px) {
  .sec-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .sec-list {
    grid-template-columns: 1fr;
  }
}
