.contact-page-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.single-col {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  gap: 5px;
  overflow: hidden;
}


.single-col > label {
  font-size: .9rem;
}

.single-col > input {
  outline: none;
  padding: 12px 15px;
  border-radius: 5px;
  font-size: 1rem;
  border: none;
  border: 1px solid #515151;
}

.single-col > textarea {
  height: 150px;
  font-size: .9rem;
  border: 1px solid #515151;
  resize: vertical;
  padding: 12px 15px;
  border-radius: 5px;
}

.contact-page-form > button {
  background-color: #000;
  color: #fff;
  border: none;
  font-size: 1rem;
  padding: 12px 20px;
  max-width: 500px;
  width: 100%;
}